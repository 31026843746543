.mm-active.mm-activ2 .ai-icon > i {
    color: #00ba52 !important;
  }
  
  [data-primary="color_2"] .deznav .metismenu > li.mm-active.mm-activ2 > a {
    background: none !important;
    color: #00ba52 !important;
    box-shadow: none !important;
  }
  
  [data-primary="color_2"] .deznav .metismenu > li.mm-active.mm-activ2 > a i {
    color: #00ba52 !important;
  }
  
  [data-primary="color_2"] .deznav .metismenu > li.hover-class:hover > a,
  [data-primary="color_2"] .deznav .metismenu > li.hover-class:hover > a i {
    color: #00ba52 !important;
    background: none !important;
    box-shadow: none !important;
  }
  