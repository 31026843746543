.profile-data .card {
  /* border-top-left-radius: 40px; */
}
.profile-data .top-section {
  padding: 1.5rem;
  margin-bottom: 40px;
  /* border-top-right-radius: 0.25rem; */
  /* border-top-left-radius: 0.25rem; */
}
.profile-data .profile-image {
  max-width: 115px;
  /* margin-bottom: -85px; */
}