/* Candidate Filters */
.candidate-filters .MuiInputBase-input {
  border-radius: 0.25rem;
  background: #fff;
  border: 1px solid #ced4da;
  color: #3e4347;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
}
.candidate-filters .MuiInputBase-input::placeholder {
  color: #3e4347;
  opacity: 0.8 !important;
}
.candidate-filters .MuiInput-underline:before {
  left: unset !important;
  right: unset !important;
  bottom: unset !important;
  content: unset !important;
  position: unset !important;
  transition: unset !important;
  border-bottom: unset !important;
  pointer-events: none !important;
}

.candidate-filters .MuiInput-underline:after {
  left: unset !important;
  right: unset !important;
  bottom: unset !important;
  content: unset !important;
  position: unset !important;
  transform: unset !important;
  transition: unset !important;
  border-bottom: unset !important;
  pointer-events: unset;
}

.candidate-filters select {
  text-transform: capitalize !important;
}