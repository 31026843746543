.fullscreen-loader {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
}

.loader-priority {
  z-index: 100;
}
